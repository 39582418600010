html, body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (orientation: portrait) {
  html {
    font-size: 16px;
  }
}
@media (orientation: landscape) {
  html {
    font-size: 20px;
  }
}